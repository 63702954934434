<template>
  <div
    class="mainEntrance"
    ref="mainEntrance"
    :style="{ cursor: isResizing ? 'col-resize' : '' }"
    v-resize-ob="viewResize"
  >
    <div
      class="grouped"
      :style="{
        padding: showUngroupedGroups ? '10px 10px' : '0',
      }"
    >
      <div
        class="groupedBox"
        :class="[
          showUngroupedGroups ? 'fillBackground dashedBorder' : '',
          dragging ? 'dragging-over' : '',
        ]"
        @dragover.prevent
        @drop="handleDrop"
        @dragenter="handleDragEnter"
        @dragleave="handleDragLeave"
      >
        <div class="searchBox" v-if="showUngroupedGroups">
          <div class="search">
            <span
              class="iconfont_Me icon-search iconStyle"
              @click.stop="handlerSearchClass"
            ></span>
            <el-input
              v-model="searchKeyWhenShowUngroupedGroups"
              @keydown.enter.native="handlerSearchClass"
            ></el-input>
          </div>
        </div>
        <template v-if="openFolderSearch">
          <div class="searchBlock">
            <div class="searchHeader">
              <i
                class="iconfont_Me icon-left iconStyle"
                @click.stop="closeSearch"
              ></i>
            </div>
            <Folders
              :folders="searchFoldersList"
              :isSearch="openFolderSearch"
              :operatingMenu="operatingMenu"
              :showUngroupedGroups="showUngroupedGroups"
              @selectThisGroupMenuItem="selectThisGroupMenuItem"
              :loading="loading"
              @selectThis="selectThis"
              @loadData="loadSearchData"
            ></Folders>
          </div>
        </template>
        <template v-else>
          <div
            class="routers"
            :style="{ height: routers.length > 1 ? '41px' : '0px' }"
          >
            <Breadcrumb
              :routers="routers"
              :refreshBreadcrumbWidth="refreshBreadcrumbWidth"
              @check="checkRouter"
              :activation="true"
            ></Breadcrumb>
          </div>
          <Folders
            :folders="foldersList"
            :operatingMenu="operatingMenu"
            :showUngroupedGroups="showUngroupedGroups"
            :loading="loading"
            @selectThisGroupMenuItem="selectThisGroupMenuItem"
            @selectThis="selectThis"
            @loadData="loadData"
          ></Folders>
          <div class="folderFooter" v-if="showUngroupedGroups">
            <div class="positioning">
              <div class="footerButton close" v-waves @click.stop="close">
                <i class="iconfont_Me icon-x iconStyle"></i>
              </div>
              <div
                class="footerButton"
                v-waves
                :class="confirmButtonDisabled ? 'disabled' : 'confirm'"
                @click.stop="confirm"
              >
                <i class="iconfont_Me icon-check iconStyle"></i>
              </div>
            </div>
          </div>
          <div class="maskLayerWhenPutIn" v-if="dragging">
            <el-image
              class="putInIcon"
              style="width: 40px; height: 40px"
              :src="
                require('../../../../../assets/images/im/myClassification/putIn.png')
              "
              :fit="'fill'"
            ></el-image>
          </div>
        </template>
      </div>
    </div>
    <div
      class="ungroupedGroups"
      :style="{
        position: showUngroupedGroups ? 'relative' : 'absolute',
        right: showUngroupedGroups ? '0' : '6px',
        top: 0,
      }"
      v-if="showsRightSidebarButton"
    >
      <div
        class="buttonStyle"
        @click.stop="startAddGroup"
        :class="showUngroupedGroups ? 'closeGroupsButton' : 'openGroupsButton'"
      >
        <el-image
          v-if="!showUngroupedGroups"
          style="width: 22px; height: 22px"
          :src="
            require('../../../../../assets/images/im/myClassification/addGroup.png')
          "
          :fit="'fill'"
        ></el-image>
        <span v-else class="iconfont_Me icon-right iconStyle"></span>
      </div>
      <div
        class="groupsList"
        :style="{
          'padding-left': showUngroupedGroups ? '16px' : '25px',
          width: showUngroupedGroups ? `${openWidth}px` : '0',
          transition: isResizing || removeTransition ? 'none' : 'all .5s',
        }"
        ref="groupsList"
      >
        <div
          class="line"
          v-show="showUngroupedGroups"
          @mousedown="startDrag"
        ></div>
        <div class="unclassifiedGroups" v-if="showUngroupedGroups">
          <UnclassifiedGroups></UnclassifiedGroups>
        </div>
      </div>
    </div>
    <div
      class="addClassification"
      v-if="!showUngroupedGroups && !loading && !openFolderSearch"
      @click.stop="addNewClass"
    >
      <el-image
        style="width: 100%; height: 100%"
        :draggable="false"
        :src="
          require('../../../../../assets/images/im/myClassification/addClassification.png')
        "
        :fit="'fill'"
      ></el-image>
    </div>
  </div>
</template>
<script>
import {
  chatClassList,
  deleteClass,
  copyClass,
  collectInfo,
  addChatClassMulti,
} from "@/api/newVersion/discussionGroupClassification";
import UnclassifiedGroups from "./components/unclassifiedGroups.vue";
import Folders from "./components/folders.vue";
import Breadcrumb from "@/newVersion/components/breadcrumb";
export default {
  inject: ["myClassification"],
  components: {
    UnclassifiedGroups,
    Folders,
    Breadcrumb,
  },
  data() {
    return {
      widthProportion: 0,
      routers: [],
      page: 1,
      pageSize: 50,
      showUngroupedGroups: false,
      removeTransition: false,
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      openWidth: "0px",
      foldersList: [],
      searchFoldersList: [],
      searchWords: "",
      operatingMenu: [
        {
          iconClassName: "icon-write",
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-a-gundongzu47",
          tooltipContent: this.$t("move"),
        },
        {
          iconClassName: "icon-Iconly-Light-Delete-copy",
          tooltipContent: this.$t("delete"),
        },
      ],
      // 通过ID查找树中元素缓存
      LeafNodeCache: {},
      lastenter: null,
      dragging: false,
      refreshBreadcrumbWidth: 0,
      refreshBreadcrumbTimer: null,
      loading: false,
      handlerLoading: false,
      openFolderSearch: false,
      searchKeyWhenShowUngroupedGroups: "",
    };
  },
  async mounted() {
    this.routers = [
      {
        name: this.$t("myCategory"),
        text: this.$t("myCategory"),
      },
    ];
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.stopDrag);
    this.page = 1;
    await this.getChatClassList();
  },
  beforeDestroy() {
    this.$store.commit("setRefreshFolderInfo", null);
    this.$store.commit("setRefreshFoldersList", false);
    this.$store.commit("setHighlightFolderIds", []);
    this.$store.commit("setShowInputFolderId", null);
    this.$store.commit("setActivatedFolderId", null);
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.stopDrag);
    clearTimeout(this.refreshBreadcrumbTimer);
  },
  watch: {
    // 监听到需要刷新页面
    refreshFoldersList: {
      async handler(val, old) {
        if (val && val != old) {
          // 需要本地更新修改名字
          if (this.refreshFolderInfo) {
            let foldersList = this.openFolderSearch
              ? this.searchFoldersList
              : this.foldersList;
            let newFolder = null;
            let folderIndex = -1;
            for (let index = 0; index < foldersList.length; index++) {
              const folder = foldersList[index];
              if (folder.id === this.refreshFolderInfo.id) {
                switch (folder.nodeType) {
                  case 0:
                    folder.nodeData = this.refreshFolderInfo.input;
                    break;
                  case 1:
                    if (folder.shortInfoVo) {
                      folder.shortInfoVo.chatName = this.refreshFolderInfo.input;
                    } else {
                      folder.nodeData = this.refreshFolderInfo.input;
                    }
                    break;
                }
                folder.refreshKey = Date.now();
                newFolder = folder;
                folderIndex = index;
                break;
              }
            }
            if (folderIndex !== -1) {
              foldersList.splice(folderIndex, 1);
              let spliceIndex = foldersList.findIndex(
                (folder) => folder.nodeType === newFolder.nodeType
              );
              if (spliceIndex === -1) {
                switch (newFolder.nodeType) {
                  case 0:
                    spliceIndex = 0;
                    break;
                  case 1:
                    spliceIndex = foldersList.length;
                    break;
                }
              }
              foldersList.splice(spliceIndex, 0, newFolder);
            }
          } else {
            this.page = 1;
            if (this.openFolderSearch) {
              await this.handlerSearch(this.searchWords);
            } else {
              await this.getChatClassList();
            }
          }
          this.$store.commit("setRefreshFolderInfo", null);
          this.$store.commit("setRefreshFoldersList", false);
        }
      },
      deep: true,
    },
    // 指定需要跳转的位置
    classifiedInfoOfNeedMove: {
      async handler(val, old) {
        if (val && val != old) {
          // 调整路由参数
          this.openFolderSearch = false;
          await this.getCollectInfo(val);
          this.page = 1;
          await this.getChatClassList();
          // 需要高亮显示
          await this.refreshHighlight([val]);
          this.$store.commit("setClassifiedInfoOfNeedMove", null);
        }
      },
      deep: true,
    },
    showUngroupedGroups: {
      async handler(val, old) {
        this.searchKeyWhenShowUngroupedGroups = "";
        if (val && val != old) {
          this.myClassification.hiddenSearch();
        } else if (!val && val != old) {
          this.myClassification.showSearch();
          const folderIndex = this.foldersList.findIndex((item) => {
            return item.nodeType == 0;
          });
          // 如果文件夹的位置不在第一个，则说明需要重新排序将文件夹排到前面
          if (folderIndex != 0) {
            const matchedfolders = this.foldersList.filter((folder) => {
              return folder.nodeType == 0;
            });
            const matchedgroups = this.foldersList.filter((folder) => {
              return folder.nodeType == 1;
            });
            this.foldersList = [...matchedfolders, ...matchedgroups];
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    // 当前路由节点
    currentRouter() {
      if (this.routers.length == 0) {
        this.routers = [
          {
            name: this.$t("myCategory"),
            text: this.$t("myCategory"),
          },
        ];
      }
      return this.routers[this.routers.length - 1];
    },
    classifiedInfoOfNeedMove() {
      return this.$store.state.myClassification.classifiedInfoOfNeedMove;
    },
    selectedUnclassifiedGroups() {
      return this.$store.state.myClassification.selectedUnclassifiedGroups;
    },
    confirmButtonDisabled() {
      return this.selectedUnclassifiedGroups.length == 0;
    },
    refreshFoldersList() {
      return this.$store.state.myClassification.refreshFoldersList;
    },
    refreshFolderInfo() {
      return this.$store.state.myClassification.refreshFolderInfo;
    },
    showsRightSidebarButton() {
      return (
        (!this.loading && !this.openFolderSearch) || this.showUngroupedGroups
      );
    },
    groupIsDragging() {
      return this.$store.state.myClassification.groupIsDragging;
    },
    showInputFolderId() {
      return this.$store.state.myClassification.showInputFolderId;
    },
  },
  methods: {
    async handlerSearchClass() {
      await this.handlerSearch(this.searchKeyWhenShowUngroupedGroups);
    },
    viewResize: _.throttle(async function(size) {
      try {
        if (!this.showUngroupedGroups) {
          return;
        }
        if (size.width * (1 - this.widthProportion) < 350) {
          this.$refs.groupsList.style.width = `${size.width - 350}px`;
        } else {
          this.$refs.groupsList.style.width = `${size.width *
            this.widthProportion}px`;
        }
      } catch (e) {}
    }, 160),
    async checkRouter(router) {
      if (router.id != "ellipsis") {
        const routerIndex = this.routers.findIndex((item) => {
          return item.id == router.id;
        });
        this.routers = this.routers.splice(0, routerIndex + 1);
        this.refreshBreadcrumbWidth = Date.now();
        this.$store.commit("setHighlightFolderIds", []);
        this.page = 1;
        await this.getChatClassList();
      }
    },
    async getCollectInfo(content) {
      const result = await collectInfo(content.id);
      if (result.code == 200) {
        this.fillRouters(result.data.data);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    fillRouters(content) {
      const handlerRouters = this.extractLayersData(content);
      handlerRouters.unshift({
        name: this.$t("myCategory"),
        text: this.$t("myCategory"),
      });
      handlerRouters.pop();
      this.routers = handlerRouters;
    },
    async close() {
      this.$store.commit("setSelectedUnclassifiedGroups", []);
      this.showUngroupedGroups = false;
    },
    closeSearch() {
      this.openFolderSearch = false;
      this.$nextTick(async () => {
        this.page = 1;
        await this.getChatClassList();
      });
    },
    async handlerSearch(key, firstRefresh = true) {
      if (!key) {
        this.openFolderSearch = false;
        this.checkRouter(this.routers[0]);
        return;
      }
      this.openFolderSearch = true;
      this.searchWords = key;
      let parentId = null;
      if (this.routers.length > 1) {
        parentId = this.currentRouter.id;
      }
      if (firstRefresh) {
        this.page = 1;
      } else {
        this.page++;
      }
      let params = {
        searchWords: this.searchWords,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      await this.getSearchClassList(params);
    },
    async confirm() {
      if (
        !this.confirmButtonDisabled &&
        this.selectedUnclassifiedGroups.length != 0
      ) {
        let parentId = null;
        if (this.routers.length > 1) {
          parentId = this.currentRouter.id;
        }
        let moveDatas = [];
        this.selectedUnclassifiedGroups.map((group) => {
          moveDatas.push({
            nodeData: group.id,
            nodeType: group.nodeType,
            parentId,
          });
        });
        await this.startMove(moveDatas);
      }
    },
    async handleDrop(event) {
      if (!this.groupIsDragging) {
        return;
      }
      event.preventDefault();
      this.dragging = false;
      if (this.openFolderSearch) {
        return;
      }
      const nodeData = parseInt(event.dataTransfer.getData("nodeData"));
      const nodeType = parseInt(event.dataTransfer.getData("nodeType"));
      const moveData = {
        nodeData,
        nodeType,
      };
      if (this.routers.length > 1) {
        moveData["parentId"] = this.currentRouter.id;
      }
      await this.startMove([moveData]);
    },
    // 开始移动
    async startMove(params) {
      let result = await addChatClassMulti(params);
      if (result.code == 200) {
        this.page = 1;
        await this.getChatClassList();
        this.$store.commit("setRefreshUnclassifiedGroups", true);
        await this.refreshHighlight(result.data.data);
        this.$message({
          type: "success",
          message: this.$t("addedSuccessfully"),
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    handleDragEnter(event) {
      if (this.groupIsDragging) {
        this.lastenter = event.target;
        this.dragging = true;
      }
    },
    handleDragLeave(event) {
      if (!this.groupIsDragging) {
        return;
      }
      if (this.lastenter === event.target) {
        this.dragging = false;
        event.stopPropagation();
        event.preventDefault();
      }
    },
    async loadSearchData(fn) {
      await this.handlerSearch(this.searchWords, false);
      if (fn) {
        fn();
      }
    },
    async getSearchClassList(data) {
      this.loading = true;
      let result = await chatClassList(data);
      if (result.code == 200) {
        if (result.data.data.length != 0 || this.page == 1) {
          let searchFoldersList = result.data.data.map((data, index) => {
            data.refreshKey = Date.now() + index;
            return data;
          });
          if (this.page == 1) {
            this.searchFoldersList = searchFoldersList;
          } else {
            this.searchFoldersList = this.searchFoldersList.concat(
              searchFoldersList
            );
          }
        } else {
          this.page = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = false;
    },
    async loadData(fn) {
      this.page++;
      await this.getChatClassList();
      if (fn) {
        fn();
      }
    },
    async getChatClassList(data) {
      if (!data) {
        data = this.currentRouter;
      }
      data = {
        ...data,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (!data.parentId && data.id) {
        data.parentId = data.id;
      }
      this.$store.commit("setRefreshFoldersList", false);
      this.loading = true;
      let result = await chatClassList(data);
      if (result.code == 200) {
        if (result.data.data.length != 0 || this.page == 1) {
          let foldersList = result.data.data.map((data, index) => {
            data.refreshKey = Date.now() + index;
            return data;
          });
          if (this.page == 1) {
            this.foldersList = foldersList;
          } else {
            this.foldersList = this.foldersList.concat(foldersList);
          }
        } else {
          this.page = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = false;
    },
    async selectThis(content) {
      if (content.nodeType != 0) {
        await this.handlerChatTo(content);
        return;
      }
      if (this.openFolderSearch) {
        await this.getRoutersBySearchData(content);
        return;
      }
      this.fillingRouters(this.routers, content);
      this.page = 1;
      await this.getChatClassList();
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          let isNumber = Number(str);
          if (isNumber) {
            return false;
          }
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    async handlerChatTo(item) {
      let content;
      try {
        content = JSON.parse(item.shortInfoVo.lastMessage.content).data;
      } catch (error) {
        content = item.shortInfoVo.lastMessage.content;
      }
      // 不需要更新
      this.needUpdateCollectState = false;
      let lastMessageContent = content;
      try {
        const isCustomer = this.isJSON(content);
        if (isCustomer) {
          const qs = JSON.parse(content);
          lastMessageContent = qs.welcomes;
        }
      } catch (e) {
        console.log(e);
      }
      let params = {
        id: item.shortInfoVo.chatId,
        displayName: item.shortInfoVo.chatName.replace(/<\/?.+?\/?>/g, ""),
        avatar: item.shortInfoVo.profilePhoto,
        isGroup: true,
        access_pattern: "3",
        at: 0,
        collected: true,
        index: undefined,
        isDelete: "1",
        isInMeeting: false,
        groupOwnerId: undefined,
        lastMessageContent: lastMessageContent,
        lastMessageType: "text",
        lastMessageTime: item.shortInfoVo.lastMessage.sendTime,
      };
      await this.$openIM(params, this.$store.state.imStore.IMWindowSize);
    },
    // 通过搜索数据获取路由
    async getRoutersBySearchData(content) {
      let newRouters = this.extractLayersData(content);
      this.routers = this.routers.splice(0, 1);
      newRouters.forEach((item) => {
        this.fillingRouters(this.routers, item);
      });
      await this.closeSearch();
    },
    // 装填路由
    fillingRouters(routers, content) {
      let isRepeat = routers.find((value) => {
        return value.id == content.id;
      });
      if (!isRepeat) {
        routers.push({
          name: content.nodeData,
          id: content.id,
          text: content.nodeData,
        });
      }
    },
    async startAddGroup() {
      if (this.refreshBreadcrumbTimer) {
        clearTimeout(this.refreshBreadcrumbTimer);
      }
      try {
        this.openWidth = this.$refs.mainEntrance.offsetWidth / 2;
        this.widthProportion = 0.5;
        await this.$nextTick();
      } catch (e) {}
      if (this.showUngroupedGroups) {
        this.close();
      } else {
        this.showUngroupedGroups = true;
      }
      this.refreshBreadcrumbTimer = setTimeout(() => {
        this.refreshBreadcrumbWidth = 1;
      }, 500);
    },
    async selectThisGroupMenuItem(index, item) {
      switch (item.nodeType) {
        case 0:
          switch (index) {
            case 0:
              this.handleEdit(item);
              break;
            case 1:
              this.handleMove(item);
              break;
            case 2:
              await this.handleDelete(item);
              break;
          }
          break;
        case 1:
          switch (index) {
            case 0:
              this.handleCopy(item);
              break;
            case 1:
              this.handleEdit(item);
              break;
            case 2:
              this.handleMove(item);
              break;
            case 3:
              await this.handleDelete(item);
              break;
          }
          break;
      }
    },
    async handleMove(item) {
      this.$store.commit("setHighlightFolderIds", []);
      this.$store.commit("setDiscussionGroupClassificationInfo", item);
      this.$store.commit(
        "setMoveDiscussionGroupClassificationPopoverMode",
        "listMode"
      );
      await this.$nextTick();
      this.$store.commit(
        "setMoveDiscussionGroupClassificationPopoverVisible",
        true
      );
    },
    async handleEdit(item) {
      this.$store.commit("setShowInputFolderId", item.id);
    },
    async handleDelete(item) {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-delete" />
            <b>?</b>
          </div>
        ),
        beforeClose: async (action, instance, done) => {
          if (action == "confirm") {
            if (!this.handlerLoading) {
              this.handlerLoading = true;
              const result = await deleteClass(item);
              if (result.code == 200) {
                this.page = 1;
                if (this.openFolderSearch) {
                  await this.handlerSearch(this.searchWords);
                } else {
                  await this.getChatClassList();
                }
                this.$store.commit("setNeedRefreshSeamlessRefresh", true);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
              this.handlerLoading = false;
            }
            done();
          } else {
            done();
          }
        },
      });
    },
    async handleCopy(item) {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-document-copy" />
            <b>?</b>
          </div>
        ),
        beforeClose: async (action, instance, done) => {
          if (action == "confirm") {
            if (!this.handlerLoading) {
              this.handlerLoading = true;
              const result = await copyClass(item.nodeData);
              if (result.code == 200) {
                const newfolder = _.cloneDeep(result.data.data);
                newfolder.shortInfoVo = _.cloneDeep(item.shortInfoVo);
                newfolder.shortInfoVo.chatId = newfolder.nodeData;
                newfolder.shortInfoVo.chatName = `${newfolder.shortInfoVo.chatName}(1)`;
                let foldersList = this.foldersList;
                if (this.openFolderSearch) {
                  foldersList = this.searchFoldersList;
                }
                let spliceIndex = 0;
                for (let i = 0; i < foldersList.length; i++) {
                  if (foldersList[i].nodeType == newfolder.nodeType) {
                    spliceIndex = i;
                    break;
                  }
                }
                foldersList.splice(spliceIndex, 0, newfolder);
                await this.refreshHighlight([newfolder]);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
              this.handlerLoading = false;
            }
            done();
          } else {
            done();
          }
        },
      });
    },
    async refreshHighlight(folders) {
      this.$store.commit("setHighlightFolderIds", []);
      if (this.showUngroupedGroups || this.classifiedInfoOfNeedMove) {
        this.moveMatchingItemsToFront(
          this.foldersList,
          folders.map((folder) => folder.id)
        );
      }
      await this.$nextTick();
      this.$store.commit(
        "setHighlightFolderIds",
        folders.map((folder) => folder.id)
      );
    },
    moveMatchingItemsToFront(arrayA, arrayB) {
      const matchedItems = [];
      arrayB.map((id) => {
        const index = arrayA.findIndex((item) => item.id === id);
        if (index !== -1) {
          matchedItems.push(arrayA.splice(index, 1)[0]);
        }
      });
      arrayA.unshift(...matchedItems);
    },
    messageBox(options) {
      options = {
        ...options,
        ...{
          title: "",
          center: true,
          customClass: "imelink-delrecord-confirm",
          confirmButtonText: " ",
          cancelButtonText: " ",
          showClose: false,
          showCancelButton: true,
          //closeOnClickModal: false,
          cancelButtonClass: "el-icon-close",
          confirmButtonClass: "el-icon-check",
        },
      };
      this.$msgbox(options).catch(() => {});
    },
    // 缩放右侧未分组群组列表
    startDrag(e) {
      this.isResizing = true;
      this.initialX = e.clientX;
      this.initialWidth = this.$refs.groupsList.offsetWidth;
    },
    handleMouseMove(e) {
      if (this.isResizing) {
        const offsetX = e.clientX - this.initialX;
        const newWidth = this.initialWidth - offsetX;
        if (this.$refs.mainEntrance.offsetWidth - 340 < newWidth) {
          return;
        }
        this.$refs.groupsList.style.width = `${newWidth}px`;
        this.widthProportion = newWidth / this.$refs.mainEntrance.offsetWidth;
        this.refreshBreadcrumbWidth = newWidth;
      }
    },
    stopDrag() {
      if (this.isResizing) {
        this.isResizing = false;
      }
    },
    addNewClass() {
      let parentId = null;
      if (this.currentRouter.id) {
        parentId = this.currentRouter.id;
      }
      this.$prompt(`${this.$t("newfolder")}（≤40）`, "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        center: true,
        showClose: true,
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
        inputPattern: /^.{1,40}$/,
        inputErrorMessage: this.$t("extralongtips"),
      })
        .then(async ({ value }) => {
          let params = {
            nodeData: value,
            nodeType: 0,
          };
          if (parentId) {
            params.parentId = parentId;
          }
          let result = await addChatClassMulti([params]);
          if (result.message == "success") {
            this.$message({
              type: "success",
              message: this.$t("addedSuccessfully"),
            });
            this.page = 1;
            await this.getChatClassList();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch(() => {});
    },
    // ----------------------------------------------------树形结构暂时不用------------------------------------------------
    // 更新树状数据结构
    updateChildrens(node, targetId, data) {
      if (node.id === targetId) {
        node.isExpanded = true;
        if (!node.childrens || node.childrens.length === 0) {
          node.childrens = data;
        } else {
          // node.childrens = node.childrens.concat(data);
          node.childrens = data;
        }
        this.updateRootRefreshKey(targetId);
      }
      if (node.childrens && node.childrens.length > 0) {
        node.childrens.forEach((child) => {
          this.updateChildrens(child, targetId, data);
        });
      }
    },
    // 更新根元素
    updateRootRefreshKey(id) {
      const leafElement = this.findLeafById(this.foldersList, id);
      if (leafElement) {
        if (leafElement.parentId == "-1") {
          leafElement.refreshKey = Date.now();
          return;
        } else {
          const rootElement = this.findRootById(
            this.foldersList,
            leafElement.parentId
          );
          if (rootElement) {
            rootElement.refreshKey = Date.now();
          }
        }
      }
    },
    findLeafById(data, targetId) {
      if (this.LeafNodeCache[targetId]) {
        return this.LeafNodeCache[targetId];
      }
      for (const item of data) {
        if (item.id === targetId) {
          this.LeafNodeCache[targetId] = item;
          return item;
        } else if (item.childrens && item.childrens.length > 0) {
          const foundInChildren = this.findLeafById(item.childrens, targetId);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    },
    findRootById(data, targetId) {
      for (const item of data) {
        if (item.id === targetId) {
          return item;
        } else if (item.childrens && item.childrens.length > 0) {
          const foundInChildren = this.findRootById(item.childrens, targetId);
          if (foundInChildren) {
            return item;
          }
        }
      }
      return null;
    },
    // 更新展开状态
    updateIsExpanded(node, targetId) {
      if (node.id === targetId) {
        node.isExpanded = !node.isExpanded;
        this.updateRootRefreshKey(targetId);
        if (!node.isExpanded) {
          node.childrens = [];
        }
      }
      if (node.childrens && node.childrens.length > 0) {
        node.childrens.forEach((child) => {
          this.updateIsExpanded(child, targetId);
        });
      }
    },
  },
};
</script>
<style lang="stylus">
.mainEntrance
  .searchBox
    .search
      .el-input
        height: 100%;
        .el-input__inner
          height: 100% !important;
          border: none;
          background: transparent;
          padding: 0 10px 0 5px !important;
</style>
<style lang="stylus" scoped>
.mainEntrance
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .grouped
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    .dragging-over
      *
        pointer-events: none;
    .groupedBox
      width: 100%;
      height: 100%;
      transition: all .5s;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .searchBlock
        width: 100%;
        min-height: 0;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .searchHeader
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-shrink: 0;
          .iconStyle
            font-size: 16px;
            color: #000000;
            margin-left: 10px;
            cursor: pointer;
      .routers
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        overflow: hidden;
      .searchBox
        width: 100%;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0 15px 0 30px;
        .search
          width: 200px;
          height: 30px;
          border-radius: 15px;
          box-sizing: border-box;
          padding-left: 5px;
          background: #EDEDED;
          border: 1px solid #D9D9D9;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .iconStyle
            font-size: 16px;
            color: #BDBEC0;
            cursor pointer;
      .maskLayerWhenPutIn
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        z-index: 1;
        background: rgba(247,247,247,0.7);
        .putInIcon
          position: absolute;
          bottom: 150px;
          left: 50%;
          transform: translate3d(-50%,0,0);
      .folderFooter
        width: 100%;
        height: 60px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .positioning
          width: 136px
          display: flex;
          align-items: center;
          justify-content: space-between;
          .footerButton
            height: 30px;
            width: 60px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background .5s;
            .iconStyle
              color: #FFFFFF;
              font-size: 14px;
          .close
            background: #E6E6E6;
          .confirm
            background: #52BD68;
          .disabled
            background: rgba(0,0,0,0.5);
            cursor: not-allowed;
    .fillBackground
      background: #F7F7F7;
    .dashedBorder
      border: 2px dashed #CFCFCF;
      border-radius: 20px;
  .ungroupedGroups
    height: 100%;
    min-width: 15px;
    flex-shrink: 0;
    background: #FFFFFF;
    width: auto;
    transition: all .5s;
    .buttonStyle
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0,-50%, 0);
      cursor pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      transition: all .5s;
      .iconStyle
        font-size: 12px;
        color: #E6E6E6;
    .closeGroupsButton
      height: 90px;
      width: 16px;
      border-radius: 16px 0 0 16px;
      background: #FFFFFF;
      border: 2px solid #E6E6E6;
      border-right: none;
    .openGroupsButton
      width: 25px;
      height: 100px;
      background: #63CB5F;
      border-radius: 25px 0 0 25px;
      box-shadow: -1px 0 4px rgba(0,0,0,0.25);
    .groupsList
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      .line
        height: 100%;
        width 4px;
        background: #E6E6E6;
        cursor: col-resize;
        flex-shrink: 0;
      .unclassifiedGroups
        height: 100%;
        flex: 1;
        overflow: hidden;
  .addClassification
    z-index: 2;
    width: 52px;
    position: absolute;
    right: 20px;
    bottom: 20px;
</style>
