<template>
  <div class="myClassification" :style="contentStyle">
    <div class="classificationHeader">
      <div class="title" v-if="showType == 1">
        <span>{{ $t("myCategory") }}</span>
      </div>
      <div class="backBlock" v-if="showType == 2">
        <span
          class="iconfont_Me icon-left iconStyle"
          @click.stop="$emit('existClassification')"
        ></span>
        <div class="groupInfo">
          <el-image
            style="width: 25px; height: 25px; border-radius: 50%"
            :src="groupInfo.avatar ? groupInfo.avatar : ''"
            :fit="'fill'"
          ></el-image>
          <div class="groupName" :title="groupInfo.displayName">
            {{ groupInfo.displayName }}
          </div>
        </div>
      </div>
      <div class="opera">
        <div class="discussion-input" v-if="showType == 1 && searchShow">
          <el-input
            class="class-input"
            size="mini"
            ref="inputs"
            v-model="searchClassValue"
            @keydown.enter.native="handlerSearchClass"
          >
            <template #suffix>
              <i
                class="el-icon-search imelink-searchbar__submit"
                @click="handlerSearchClass"
              ></i>
            </template>
          </el-input>
        </div>
        <div>
          <span
            class="imelink-miniscreen"
            v-show="this.IMWindowSize != 'mini'"
            @click="changeScreen('mini')"
          >
            <SizeButton :buttonType="'minimize'"></SizeButton>
          </span>
          <span
            class="imelink-fullscreen"
            v-show="this.IMWindowSize == 'medium'"
            @click="changeScreen('full')"
          >
            <SizeButton></SizeButton>
          </span>
          <span
            class="imelink-fullscreen"
            v-show="this.IMWindowSize == 'full'"
            @click="changeScreen('medium')"
          >
            <SizeButton :buttonType="'full'"></SizeButton>
          </span>
        </div>
      </div>
    </div>
    <div class="classificationContent">
      <MainEntrance v-if="showType == 1" ref="mainEntrance"></MainEntrance>
      <SecondaryEntrance v-else-if="showType == 2"></SecondaryEntrance>
    </div>
  </div>
</template>
<script>
import SizeButton from "@/newVersion/components/im_size_button";
import MainEntrance from "./mainEntrance.vue";
import SecondaryEntrance from "./secondaryEntrance.vue";
export default {
  inject: ["control"],
  name: "myClassification",
  provide() {
    return {
      myClassification: this,
    };
  },
  components: {
    SizeButton,
    MainEntrance,
    SecondaryEntrance,
  },
  data() {
    return {
      contentStyle: {
        "margin-left": "56px",
      },
      searchClassValue: "",
      searchShow: true,
    };
  },
  props: {
    showType: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
    openSearch() {
      return this.$store.state.myClassification.openSearch;
    },
    existingClassifiedGroup() {
      return this.$store.state.myClassification.existingClassifiedGroup;
    },
    groupInfo() {
      return this.$store.state.myClassification.existingClassifiedGroupInfo;
    },
  },
  watch: {
    IMWindowSize: {
      handler(val, old) {
        if (val && val != old && val == "medium") {
          this.contentStyle["margin-left"] = "0px";
        } else {
          this.contentStyle["margin-left"] = "56px";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    hiddenSearch() {
      this.searchShow = false;
    },
    showSearch() {
      this.searchShow = true;
    },
    handlerSearchClass() {
      switch (this.showType) {
        case 1:
          this.$refs.mainEntrance.handlerSearch(this.searchClassValue);
          break;
        case 2:
          break;
      }
    },
    changeScreen(type) {
      if (!["full", "medium", "mini"].includes(type)) return;
      this.$store.commit("setIMWindowSize", type);
    },
  },
};
</script>
<style lang="stylus" scoped>
.myClassification
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  user-select: none;
  transition: margin 0.3s;
  .classificationHeader
    overflow: hidden;
    line-height: 54px !important;
    height: 54px !important;
    background: #f9f9f9;
    font-size: 16px;
    border-right: 1px solid #eee;
    display: flex;
    padding-left: 22px;
    align-items: center;
    flex-shrink: 0;
    .backBlock
      flex: 5;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow hidden;
      .iconStyle
        color: #000000;
        font-size: 14px;
        cursor pointer;
        width: 16px;
        flex-shrink: 0;
      .groupInfo
        flex: 1;
        min-width: 0;
        height: 25px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 5px;
        .groupName
          width: calc(100% - 100px);
          font-size: 14px;
          color: #000000;
          margin-left: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis
    .title
      flex: 4;
      font-size: 14px;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      border-right: 1px solid #ededed;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      span
        text-overflow: ellipsis;
        white-space: nowrap;
    .opera
      box-sizing: border-box;
      padding: 0 22px 0 18px;
      width: 35%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .discussion-input
        flex: 1;
      .class-input
        width: 70%;
      .el-icon-minus
        cursor: pointer;
        margin-right: 10px;
      .el-icon-copy-document
        cursor: pointer;
  .classificationContent
    width: 100%;
    height: 100%;
    overflow: hidden;
</style>
