<template>
  <div class="secondaryEntrance">
    <div class="entranceHeader">
      <div class="headerContent">
        <span class="entranceTitle">{{ this.$t("myCategory") }}</span>
        <div class="switchBox">
          <div
            class="typeStyle"
            v-for="i in typeList"
            :key="i.url"
            @click.stop="changeShowType(i)"
          >
            <el-image
              style="width: 100%; height: 100%"
              :src="i.url"
              :fit="'fill'"
            ></el-image>
          </div>
        </div>
        <div class="inputBox">
          <div
            class="iconfont_Me icon-search iconStyle"
            @click.stop="handlerSearch"
          ></div>
          <el-input
            v-model="searchKey"
            @keydown.enter.native="handlerSearch"
          ></el-input>
        </div>
      </div>
      <div class="headerLine"></div>
    </div>
    <div class="entranceBody">
      <template v-if="openFolderSearch">
        <div class="searchBlock">
          <div class="searchHeader">
            <i
              class="iconfont_Me icon-left iconStyle"
              @click.stop="closeSearch"
            ></i>
          </div>
          <Folders
            :folders="searchFoldersList"
            :isSearch="openFolderSearch"
            :operatingMenu="operatingMenu"
            :foldersType="showFoldersType"
            :modifyMode="showModifyMode"
            :loading="loading"
            @selectThisGroupMenuItem="selectThisGroupMenuItem"
            @selectThis="selectThis"
            @loadData="loadSearchData"
          ></Folders></div
      ></template>
      <template v-else>
        <div
          class="routers"
          v-if="routers.length != 0"
          v-resize-ob="handlerRouterSize"
          :style="{ height: routers.length > 1 ? '30px' : '10px' }"
        >
          <Breadcrumb
            v-if="routers.length > 1"
            :routers="routers"
            :refreshBreadcrumbWidth="refreshBreadcrumbWidth"
            @check="checkRouter"
            :activation="true"
          ></Breadcrumb>
        </div>
        <Folders
          :folders="foldersList"
          :operatingMenu="operatingMenu"
          :loading="loading"
          :foldersType="showFoldersType"
          :modifyMode="showModifyMode"
          @selectThisGroupMenuItem="selectThisGroupMenuItem"
          @selectThis="selectThis"
          @loadData="loadData"
        ></Folders>
        <div class="operationBox" v-if="!showModifyMode">
          <div class="buttonStyle close" v-waves @click.stop="closeAddMode">
            <i class="iconfont_Me icon-x iconStyle"></i>
          </div>
          <div class="buttonStyle confirm" v-waves @click.stop="confirmAddMode">
            <i class="iconfont_Me icon-check iconStyle"></i>
          </div>
        </div>
      </template>
    </div>
    <div
      class="addClassification"
      @click.stop="addNewClass"
      v-if="!loading && !openFolderSearch"
    >
      <el-image
        style="width: 100%; height: 100%"
        :src="
          require('../../../../../assets/images/im/myClassification/addClassification.png')
        "
        :fit="'fill'"
      ></el-image>
    </div>
  </div>
</template>
<script>
import {
  collectInfo,
  chatClassList,
  addChatClassMulti,
  deleteClass,
  copyClass,
} from "@/api/newVersion/discussionGroupClassification";
import Breadcrumb from "@/newVersion/components/breadcrumb";
import Folders from "./components/folders.vue";
export default {
  components: {
    Folders,
    Breadcrumb,
  },
  data() {
    return {
      showFoldersType: "listMode",
      searchKey: "",
      routers: [],
      page: 1,
      pageSize: 50,
      foldersList: [],
      searchFoldersList: [],
      refreshBreadcrumbWidth: 0,
      loading: false,
      handlerLoading: false,
      completedAdding: false,
      openFolderSearch: false,
    };
  },
  computed: {
    operatingMenu() {
      let menu = [];
      menu = [
        {
          iconClassName: "icon-write",
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-a-gundongzu47",
          tooltipContent: this.$t("move"),
        },
        {
          iconClassName: "icon-Iconly-Light-Delete-copy",
          tooltipContent: this.$t("delete"),
        },
      ];
      return menu;
    },
    // 当前路由节点
    currentRouter() {
      if (this.routers.length == 0) {
        this.routers = [
          {
            name: this.$t("myCategory"),
            text: this.$t("myCategory"),
          },
        ];
      }
      return this.routers[this.routers.length - 1];
    },
    addOrModify() {
      return this.existingClassifiedGroup ? "modify" : "add";
    },
    showModifyMode() {
      return this.addOrModify == "modify" || this.completedAdding;
    },
    existingClassifiedGroup() {
      return this.$store.state.myClassification.existingClassifiedGroup;
    },
    groupInfo() {
      return this.$store.state.myClassification.existingClassifiedGroupInfo;
    },
    typeList() {
      const listUrl =
        this.showFoldersType == "listMode"
          ? require("../../../../../assets/images/class/listType_selected.png")
          : require("../../../../../assets/images/class/listType_notSelected.png");
      const iconUrl =
        this.showFoldersType == "iconMode"
          ? require("../../../../../assets/images/class/iconType_selected.png")
          : require("../../../../../assets/images/class/iconType_notSelected.png");
      return [
        {
          name: "listMode",
          url: listUrl,
        },
        {
          name: "iconMode",
          url: iconUrl,
        },
      ];
    },
    refreshFoldersList() {
      return this.$store.state.myClassification.refreshFoldersList;
    },
    refreshFolderInfo() {
      return this.$store.state.myClassification.refreshFolderInfo;
    },
    classifiedInfoOfNeedMove() {
      return this.$store.state.myClassification.classifiedInfoOfNeedMove;
    },
  },
  watch: {
    // 监听到需要刷新页面
    refreshFoldersList: {
      async handler(val, old) {
        if (val && val != old) {
          // 判断是否是修改名字需要刷新某一条
          if (this.refreshFolderInfo) {
            let foldersList = this.openFolderSearch
              ? this.searchFoldersList
              : this.foldersList;
            let newFolder = null;
            let folderIndex = -1;
            for (let index = 0; index < foldersList.length; index++) {
              const folder = foldersList[index];
              if (folder.id === this.refreshFolderInfo.id) {
                switch (folder.nodeType) {
                  case 0:
                    folder.nodeData = this.refreshFolderInfo.input;
                    break;
                  case 1:
                    if (folder.shortInfoVo) {
                      folder.shortInfoVo.chatName = this.refreshFolderInfo.input;
                    } else {
                      folder.nodeData = this.refreshFolderInfo.input;
                    }
                    break;
                }
                folder.refreshKey = Date.now();
                newFolder = folder;
                folderIndex = index;
                break;
              }
            }
            if (folderIndex !== -1) {
              foldersList.splice(folderIndex, 1);
              let spliceIndex = foldersList.findIndex(
                (folder) => folder.nodeType === newFolder.nodeType
              );
              if (spliceIndex === -1) {
                switch (newFolder.nodeType) {
                  case 0:
                    spliceIndex = 0;
                    break;
                  case 1:
                    spliceIndex = foldersList.length;
                    break;
                }
              }
              foldersList.splice(spliceIndex, 0, newFolder);
            }
          } else {
            this.page = 1;
            if (this.openFolderSearch) {
              await this.handlerSearch();
            } else {
              await this.getChatClassList();
            }
          }
          this.$store.commit("setRefreshFolderInfo", null);
          this.$store.commit("setRefreshFoldersList", false);
        }
      },
      deep: true,
    },
    // 指定需要跳转的位置
    classifiedInfoOfNeedMove: {
      async handler(val, old) {
        if (val && val != old) {
          // 调整路由参数
          this.openFolderSearch = false;
          await this.getCollectInfo(val);
          this.page = 1;
          await this.getChatClassList();
          // 需要高亮显示
          await this.refreshHighlight([val]);
          this.$store.commit("setClassifiedInfoOfNeedMove", null);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.addOrModify == "modify") {
      await this.getCollectInfo(this.existingClassifiedGroup);
    } else {
      this.routers = [
        {
          name: this.$t("myCategory"),
          text: this.$t("myCategory"),
        },
      ];
    }
    this.page = 1;
    await this.getChatClassList();
  },
  beforeDestroy() {
    this.$store.commit("setExistingClassifiedGroupInfo", null);
    this.$store.commit("setExistingClassifiedGroup", null);
    this.$store.commit("setHighlightFolderIds", []);
    this.$store.commit("setRefreshFolderInfo", null);
    this.$store.commit("setRefreshFoldersList", false);
    this.$store.commit("setShowInputFolderId", null);
    this.$store.commit("setActivatedFolderId", null);
  },
  methods: {
    closeSearch() {
      this.openFolderSearch = false;
      this.$nextTick(async () => {
        this.page = 1;
        await this.getChatClassList();
      });
    },
    async handlerSearch(firstRefresh = true) {
      if (!this.searchKey) {
        this.openFolderSearch = false;
        this.checkRouter(this.routers[0]);
        return;
      }
      this.openFolderSearch = true;
      let parentId = null;
      if (this.routers.length > 1) {
        parentId = this.currentRouter.id;
      }
      if (firstRefresh) {
        this.page = 1;
      } else {
        this.page++;
      }
      let params = {
        searchWords: this.searchKey,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      await this.getSearchClassList(params);
    },
    async loadSearchData(fn) {
      await this.handlerSearch(false);
      if (fn) {
        fn();
      }
    },
    async getSearchClassList(data) {
      this.loading = true;
      let result = await chatClassList(data);
      if (result.code == 200) {
        if (result.data.data.length != 0 || this.page == 1) {
          let searchFoldersList = result.data.data.map((data, index) => {
            data.refreshKey = Date.now() + index;
            return data;
          });
          if (this.page == 1) {
            this.searchFoldersList = searchFoldersList;
          } else {
            this.searchFoldersList = this.searchFoldersList.concat(
              searchFoldersList
            );
          }
        } else {
          this.page = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = false;
    },
    closeAddMode() {
      this.completedAdding = true;
    },
    async confirmAddMode() {
      let parentId = null;
      if (this.currentRouter.id) {
        parentId = this.currentRouter.id;
      }
      let params = {
        nodeData: this.groupInfo.id,
        nodeType: 1,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      let result = await addChatClassMulti([params]);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: this.$t("addedSuccessfully"),
        });
        this.closeAddMode();
        this.page = 1;
        await this.getChatClassList();
        await this.refreshHighlight(result.data.data);
        // 将本次添加收藏的会话ID存储，用于标记IM聊天中搜索置顶的会话
        this.$store.commit("setMarkedAsFavoriteChat", this.groupInfo.id);
        this.$store.commit("setNeedRefreshSeamlessRefresh", true);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    addNewClass() {
      let parentId = null;
      if (this.currentRouter.id) {
        parentId = this.currentRouter.id;
      }
      this.$prompt(`${this.$t("newfolder")}（≤40）`, "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        center: true,
        showClose: true,
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
        inputPattern: /^.{1,40}$/,
        inputErrorMessage: this.$t("extralongtips"),
      })
        .then(async ({ value }) => {
          let params = {
            nodeData: value,
            nodeType: 0,
          };
          if (parentId) {
            params.parentId = parentId;
          }
          let result = await addChatClassMulti([params]);
          if (result.message == "success") {
            this.$message({
              type: "success",
              message: this.$t("addedSuccessfully"),
            });
            this.page = 1;
            await this.getChatClassList();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch(() => {});
    },
    async selectThis(content) {
      if (content.nodeType != 0) {
        await this.handlerChatTo(content);
        return;
      }
      if (this.openFolderSearch) {
        await this.getRoutersBySearchData(content);
        return;
      }
      this.fillingRouters(this.routers, content);
      this.page = 1;
      await this.getChatClassList();
    },
    // 通过搜索数据获取路由
    async getRoutersBySearchData(content) {
      let newRouters = this.extractLayersData(content);
      this.routers = this.routers.splice(0, 1);
      newRouters.forEach((item) => {
        this.fillingRouters(this.routers, item);
      });
      await this.closeSearch();
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          let isNumber = Number(str);
          if (isNumber) {
            return false;
          }
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    async handlerChatTo(item) {
      let content;
      try {
        content = JSON.parse(item.shortInfoVo.lastMessage.content).data;
      } catch (error) {
        content = item.shortInfoVo.lastMessage.content;
      }
      // 不需要更新
      this.needUpdateCollectState = false;
      let lastMessageContent = content;
      try {
        const isCustomer = this.isJSON(content);
        if (isCustomer) {
          const qs = JSON.parse(content);
          lastMessageContent = qs.welcomes;
        }
      } catch (e) {
        console.log(e);
      }
      let params = {
        id: item.shortInfoVo.chatId,
        displayName: item.shortInfoVo.chatName.replace(/<\/?.+?\/?>/g, ""),
        avatar: item.shortInfoVo.profilePhoto,
        isGroup: true,
        access_pattern: "3",
        at: 0,
        collected: true,
        index: undefined,
        isDelete: "1",
        isInMeeting: false,
        groupOwnerId: undefined,
        lastMessageContent: lastMessageContent,
        lastMessageType: "text",
        lastMessageTime: item.shortInfoVo.lastMessage.sendTime,
      };
      await this.$openIM(params, this.$store.state.imStore.IMWindowSize);
    },
    // 装填路由
    fillingRouters(routers, content) {
      let isRepeat = routers.find((value) => {
        return value.id == content.id;
      });
      if (!isRepeat) {
        routers.push({
          name: content.nodeData,
          id: content.id,
          text: content.nodeData,
        });
      }
    },
    async checkRouter(router) {
      if (router.id != "ellipsis") {
        const routerIndex = this.routers.findIndex((item) => {
          return item.id == router.id;
        });
        this.routers = this.routers.splice(0, routerIndex + 1);
        this.refreshBreadcrumbWidth = Date.now();
        this.$store.commit("setHighlightFolderIds", []);
        this.page = 1;
        await this.getChatClassList();
      }
    },
    handlerRouterSize(size) {
      this.refreshBreadcrumbWidth = Date.now();
    },
    async getCollectInfo(content) {
      const result = await collectInfo(content.id);
      if (result.code == 200) {
        this.fillRouters(result.data.data);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    fillRouters(content) {
      const handlerRouters = this.extractLayersData(content);
      handlerRouters.unshift({
        name: this.$t("myCategory"),
        text: this.$t("myCategory"),
      });
      handlerRouters.pop();
      this.routers = handlerRouters;
    },
    async loadData(fn) {
      this.page++;
      await this.getChatClassList();
      if (fn) {
        fn();
      }
    },
    async getChatClassList(data) {
      if (!data) {
        data = this.currentRouter;
      }
      data = {
        ...data,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (!data.parentId && data.id) {
        data.parentId = data.id;
      }
      this.loading = true;
      this.$store.commit("setRefreshFoldersList", false);
      let result = await chatClassList(data);
      if (result.code == 200) {
        if (result.data.data.length != 0 || this.page == 1) {
          let foldersList = result.data.data.map((data, index) => {
            data.refreshKey = Date.now() + index;
            return data;
          });
          if (this.page == 1) {
            this.foldersList = foldersList;
          } else {
            this.foldersList = this.foldersList.concat(foldersList);
          }
        } else {
          this.page = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.loading = false;
    },
    changeShowType(i) {
      this.$store.commit("setShowInputFolderId", null);
      this.showFoldersType = i.name;
    },
    async selectThisGroupMenuItem(index, item) {
      switch (item.nodeType) {
        case 0:
          switch (index) {
            case 0:
              this.handleEdit(item);
              break;
            case 1:
              this.handleMove(item);
              break;
            case 2:
              await this.handleDelete(item);
              break;
          }
          break;
        case 1:
          switch (index) {
            case 0:
              this.handleCopy(item);
              break;
            case 1:
              this.handleEdit(item);
              break;
            case 2:
              this.handleMove(item);
              break;
            case 3:
              await this.handleDelete(item);
              break;
          }
          break;
      }
    },
    handleMove(item) {
      this.$store.commit("setHighlightFolderIds", []);
      this.$store.commit("setDiscussionGroupClassificationInfo", item);
      this.$store.commit(
        "setMoveDiscussionGroupClassificationPopoverMode",
        this.showFoldersType
      );
      this.$store.commit(
        "setMoveDiscussionGroupClassificationPopoverVisible",
        true
      );
    },
    async handleEdit(item) {
      this.$store.commit("setShowInputFolderId", item.id);
    },
    async handleDelete(item) {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-delete" />
            <b>?</b>
          </div>
        ),
        beforeClose: async (action, instance, done) => {
          if (action == "confirm") {
            if (!this.handlerLoading) {
              this.handlerLoading = true;
              const result = await deleteClass(item);
              if (result.code == 200) {
                this.page = 1;
                if (this.openFolderSearch) {
                  await this.handlerSearch();
                } else {
                  await this.getChatClassList();
                }
                this.$store.commit("setNeedRefreshSeamlessRefresh", true);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
              this.handlerLoading = false;
            }
            done();
          } else {
            done();
          }
        },
      });
    },
    async handleCopy(item) {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-document-copy" />
            <b>?</b>
          </div>
        ),
        beforeClose: async (action, instance, done) => {
          if (action == "confirm") {
            if (!this.handlerLoading) {
              this.handlerLoading = true;
              const result = await copyClass(item.nodeData);
              if (result.code == 200) {
                const newfolder = _.cloneDeep(result.data.data);
                newfolder.shortInfoVo = _.cloneDeep(item.shortInfoVo);
                newfolder.shortInfoVo.chatId = newfolder.nodeData;
                newfolder.shortInfoVo.chatName = `${item.shortInfoVo.chatName}(1)`;
                let foldersList = this.foldersList;
                if (this.openFolderSearch) {
                  foldersList = this.searchFoldersList;
                }
                let spliceIndex = 0;
                for (let i = 0; i < foldersList.length; i++) {
                  if (foldersList[i].nodeType == newfolder.nodeType) {
                    spliceIndex = i;
                    break;
                  }
                }
                foldersList.splice(spliceIndex, 0, newfolder);
                await this.refreshHighlight([newfolder]);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
              this.handlerLoading = false;
            }
            done();
          } else {
            done();
          }
        },
      });
    },
    async refreshHighlight(folders) {
      this.$store.commit("setHighlightFolderIds", []);
      if (this.classifiedInfoOfNeedMove) {
        this.moveMatchingItemsToFront(
          this.foldersList,
          folders.map((folder) => folder.id)
        );
      }
      await this.$nextTick();
      this.$store.commit(
        "setHighlightFolderIds",
        folders.map((folder) => folder.id)
      );
    },
    moveMatchingItemsToFront(arrayA, arrayB) {
      const matchedItems = [];
      arrayB.map((id) => {
        const index = arrayA.findIndex((item) => item.id === id);
        if (index !== -1) {
          matchedItems.push(arrayA.splice(index, 1)[0]);
        }
      });
      arrayA.unshift(...matchedItems);
    },
    messageBox(options) {
      options = {
        ...options,
        ...{
          title: "",
          center: true,
          customClass: "imelink-delrecord-confirm",
          confirmButtonText: " ",
          cancelButtonText: " ",
          showClose: false,
          showCancelButton: true,
          //closeOnClickModal: false,
          cancelButtonClass: "el-icon-close",
          confirmButtonClass: "el-icon-check",
        },
      };
      this.$msgbox(options).catch(() => {});
    },
  },
};
</script>
<style lang="stylus">
.secondaryEntrance
  .el-input
    height: 100%;
    border: none;
    .el-input__inner
      border: none;
      height: 100%;
      padding: 0;
      background: transparent;
</style>
<style lang="stylus" scoped>
.secondaryEntrance
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .entranceHeader
    width: 100%;
    height: 41px;
    flex-shrink: 0;
    padding: 0 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .headerContent
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .entranceTitle
        font-size: 14px;
        font-weight: bold;
        color: #000000;
      .switchBox
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .typeStyle
          height: 16px;
          width: 16px;
          margin-left: 13px;
      .inputBox
        width: 160px;
        height: 26px;
        box-sizing: border-box;
        border-radius: 13px;
        margin-left: 10px;
        background: #EDEDED;
        overflow: hidden;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .iconStyle
          font-size: 14px
          color: #BDBEC0;
          cursor: pointer;
          margin-right: 5px;
    .headerLine
      width: 100%;
      height: 1px;
      background: #F5F5F5;
  .entranceBody
    width: 100%;
    min-height: 0;
    flex: 1;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .searchBlock
      width: 100%;
      height: 100%;
      flex: 1;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .searchHeader
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        .iconStyle
          font-size: 16px;
          color: #000000;
          margin-left: 10px;
          cursor: pointer;
    .routers
      width: 100%;
      height: 30px;
      flex-shrink: 0;
      padding 0 22px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    .operationBox
      width: 100%;
      height: 90px;
      flex-shrink: 0;
      padding 10px 22px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      .buttonStyle
        height: 30px;
        width: 100px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          color: #FFFFFF;
          font-size: 18px;
      .close
        background: #E6E6E6;
        margin-right: 15px;
      .confirm
        background: #52BD68;
  .addClassification
    z-index: 2;
    width: 52px;
    position: absolute;
    right: 20px;
    bottom: 20px;
</style>
