var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "unclassifiedGroupsBox",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c("div", { staticClass: "searchBox" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("span", {
              staticClass: "iconfont_Me icon-search iconStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handlerSearchClass.apply(null, arguments)
                }
              }
            }),
            _c("el-input", {
              nativeOn: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handlerSearchClass.apply(null, arguments)
                }
              },
              model: {
                value: _vm.searchKey,
                callback: function($$v) {
                  _vm.searchKey = $$v
                },
                expression: "searchKey"
              }
            })
          ],
          1
        )
      ]),
      _vm.list.length != 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadData,
                  expression: "loadData"
                }
              ],
              staticClass: "groups",
              attrs: {
                "infinite-scroll-disabled": "loadDataDisabled",
                "infinite-scroll-distance": "10",
                "infinite-scroll-immediate-check": "false"
              }
            },
            _vm._l(_vm.list, function(i, index) {
              return _c(
                "div",
                {
                  key: i.id + i.selected,
                  staticClass: "group",
                  attrs: { draggable: "true" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.check(index)
                    },
                    dragstart: function($event) {
                      return _vm.handleDragStart($event, index)
                    },
                    dragend: function($event) {
                      return _vm.handleDragEnd($event, index)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "checkBox",
                      class: i.selected ? "selected" : ""
                    },
                    [
                      i.selected
                        ? _c("i", {
                            staticClass: "iconfont_Me icon-check iconStyle"
                          })
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "groupItem", attrs: { title: i.name } },
                    [
                      _c(
                        "div",
                        { staticClass: "photo" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: i.profilePhoto, fit: "fill" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "name" }, [
                        _vm._v("\n          " + _vm._s(i.name) + "\n        ")
                      ]),
                      _vm.userId == i.groupOwnerId
                        ? _c("i", {
                            staticClass: "iconfont_Me icon-qunzhu ownerIcon"
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "placeholderMap" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }