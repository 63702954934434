var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "secondaryEntrance" }, [
    _c("div", { staticClass: "entranceHeader" }, [
      _c("div", { staticClass: "headerContent" }, [
        _c("span", { staticClass: "entranceTitle" }, [
          _vm._v(_vm._s(this.$t("myCategory")))
        ]),
        _c(
          "div",
          { staticClass: "switchBox" },
          _vm._l(_vm.typeList, function(i) {
            return _c(
              "div",
              {
                key: i.url,
                staticClass: "typeStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.changeShowType(i)
                  }
                }
              },
              [
                _c("el-image", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { src: i.url, fit: "fill" }
                })
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "inputBox" },
          [
            _c("div", {
              staticClass: "iconfont_Me icon-search iconStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handlerSearch.apply(null, arguments)
                }
              }
            }),
            _c("el-input", {
              nativeOn: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handlerSearch.apply(null, arguments)
                }
              },
              model: {
                value: _vm.searchKey,
                callback: function($$v) {
                  _vm.searchKey = $$v
                },
                expression: "searchKey"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "headerLine" })
    ]),
    _c(
      "div",
      { staticClass: "entranceBody" },
      [
        _vm.openFolderSearch
          ? [
              _c(
                "div",
                { staticClass: "searchBlock" },
                [
                  _c("div", { staticClass: "searchHeader" }, [
                    _c("i", {
                      staticClass: "iconfont_Me icon-left iconStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeSearch.apply(null, arguments)
                        }
                      }
                    })
                  ]),
                  _c("Folders", {
                    attrs: {
                      folders: _vm.searchFoldersList,
                      isSearch: _vm.openFolderSearch,
                      operatingMenu: _vm.operatingMenu,
                      foldersType: _vm.showFoldersType,
                      modifyMode: _vm.showModifyMode,
                      loading: _vm.loading
                    },
                    on: {
                      selectThisGroupMenuItem: _vm.selectThisGroupMenuItem,
                      selectThis: _vm.selectThis,
                      loadData: _vm.loadSearchData
                    }
                  })
                ],
                1
              )
            ]
          : [
              _vm.routers.length != 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "resize-ob",
                          rawName: "v-resize-ob",
                          value: _vm.handlerRouterSize,
                          expression: "handlerRouterSize"
                        }
                      ],
                      staticClass: "routers",
                      style: {
                        height: _vm.routers.length > 1 ? "30px" : "10px"
                      }
                    },
                    [
                      _vm.routers.length > 1
                        ? _c("Breadcrumb", {
                            attrs: {
                              routers: _vm.routers,
                              refreshBreadcrumbWidth:
                                _vm.refreshBreadcrumbWidth,
                              activation: true
                            },
                            on: { check: _vm.checkRouter }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("Folders", {
                attrs: {
                  folders: _vm.foldersList,
                  operatingMenu: _vm.operatingMenu,
                  loading: _vm.loading,
                  foldersType: _vm.showFoldersType,
                  modifyMode: _vm.showModifyMode
                },
                on: {
                  selectThisGroupMenuItem: _vm.selectThisGroupMenuItem,
                  selectThis: _vm.selectThis,
                  loadData: _vm.loadData
                }
              }),
              !_vm.showModifyMode
                ? _c("div", { staticClass: "operationBox" }, [
                    _c(
                      "div",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "buttonStyle close",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeAddMode.apply(null, arguments)
                          }
                        }
                      },
                      [_c("i", { staticClass: "iconfont_Me icon-x iconStyle" })]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "buttonStyle confirm",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.confirmAddMode.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont_Me icon-check iconStyle"
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ]
      ],
      2
    ),
    !_vm.loading && !_vm.openFolderSearch
      ? _c(
          "div",
          {
            staticClass: "addClassification",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.addNewClass.apply(null, arguments)
              }
            }
          },
          [
            _c("el-image", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                src: require("../../../../../assets/images/im/myClassification/addClassification.png"),
                fit: "fill"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }