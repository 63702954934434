var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-ob",
          rawName: "v-resize-ob",
          value: _vm.viewResize,
          expression: "viewResize"
        }
      ],
      ref: "mainEntrance",
      staticClass: "mainEntrance",
      style: { cursor: _vm.isResizing ? "col-resize" : "" }
    },
    [
      _c(
        "div",
        {
          staticClass: "grouped",
          style: {
            padding: _vm.showUngroupedGroups ? "10px 10px" : "0"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "groupedBox",
              class: [
                _vm.showUngroupedGroups ? "fillBackground dashedBorder" : "",
                _vm.dragging ? "dragging-over" : ""
              ],
              on: {
                dragover: function($event) {
                  $event.preventDefault()
                },
                drop: _vm.handleDrop,
                dragenter: _vm.handleDragEnter,
                dragleave: _vm.handleDragLeave
              }
            },
            [
              _vm.showUngroupedGroups
                ? _c("div", { staticClass: "searchBox" }, [
                    _c(
                      "div",
                      { staticClass: "search" },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-search iconStyle",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handlerSearchClass.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        }),
                        _c("el-input", {
                          nativeOn: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlerSearchClass.apply(
                                null,
                                arguments
                              )
                            }
                          },
                          model: {
                            value: _vm.searchKeyWhenShowUngroupedGroups,
                            callback: function($$v) {
                              _vm.searchKeyWhenShowUngroupedGroups = $$v
                            },
                            expression: "searchKeyWhenShowUngroupedGroups"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.openFolderSearch
                ? [
                    _c(
                      "div",
                      { staticClass: "searchBlock" },
                      [
                        _c("div", { staticClass: "searchHeader" }, [
                          _c("i", {
                            staticClass: "iconfont_Me icon-left iconStyle",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.closeSearch.apply(null, arguments)
                              }
                            }
                          })
                        ]),
                        _c("Folders", {
                          attrs: {
                            folders: _vm.searchFoldersList,
                            isSearch: _vm.openFolderSearch,
                            operatingMenu: _vm.operatingMenu,
                            showUngroupedGroups: _vm.showUngroupedGroups,
                            loading: _vm.loading
                          },
                          on: {
                            selectThisGroupMenuItem:
                              _vm.selectThisGroupMenuItem,
                            selectThis: _vm.selectThis,
                            loadData: _vm.loadSearchData
                          }
                        })
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "routers",
                        style: {
                          height: _vm.routers.length > 1 ? "41px" : "0px"
                        }
                      },
                      [
                        _c("Breadcrumb", {
                          attrs: {
                            routers: _vm.routers,
                            refreshBreadcrumbWidth: _vm.refreshBreadcrumbWidth,
                            activation: true
                          },
                          on: { check: _vm.checkRouter }
                        })
                      ],
                      1
                    ),
                    _c("Folders", {
                      attrs: {
                        folders: _vm.foldersList,
                        operatingMenu: _vm.operatingMenu,
                        showUngroupedGroups: _vm.showUngroupedGroups,
                        loading: _vm.loading
                      },
                      on: {
                        selectThisGroupMenuItem: _vm.selectThisGroupMenuItem,
                        selectThis: _vm.selectThis,
                        loadData: _vm.loadData
                      }
                    }),
                    _vm.showUngroupedGroups
                      ? _c("div", { staticClass: "folderFooter" }, [
                          _c("div", { staticClass: "positioning" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                staticClass: "footerButton close",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.close.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont_Me icon-x iconStyle"
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                staticClass: "footerButton",
                                class: _vm.confirmButtonDisabled
                                  ? "disabled"
                                  : "confirm",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.confirm.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont_Me icon-check iconStyle"
                                })
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.dragging
                      ? _c(
                          "div",
                          { staticClass: "maskLayerWhenPutIn" },
                          [
                            _c("el-image", {
                              staticClass: "putInIcon",
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("../../../../../assets/images/im/myClassification/putIn.png"),
                                fit: "fill"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        ]
      ),
      _vm.showsRightSidebarButton
        ? _c(
            "div",
            {
              staticClass: "ungroupedGroups",
              style: {
                position: _vm.showUngroupedGroups ? "relative" : "absolute",
                right: _vm.showUngroupedGroups ? "0" : "6px",
                top: 0
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "buttonStyle",
                  class: _vm.showUngroupedGroups
                    ? "closeGroupsButton"
                    : "openGroupsButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startAddGroup.apply(null, arguments)
                    }
                  }
                },
                [
                  !_vm.showUngroupedGroups
                    ? _c("el-image", {
                        staticStyle: { width: "22px", height: "22px" },
                        attrs: {
                          src: require("../../../../../assets/images/im/myClassification/addGroup.png"),
                          fit: "fill"
                        }
                      })
                    : _c("span", {
                        staticClass: "iconfont_Me icon-right iconStyle"
                      })
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "groupsList",
                  staticClass: "groupsList",
                  style: {
                    "padding-left": _vm.showUngroupedGroups ? "16px" : "25px",
                    width: _vm.showUngroupedGroups ? `${_vm.openWidth}px` : "0",
                    transition:
                      _vm.isResizing || _vm.removeTransition
                        ? "none"
                        : "all .5s"
                  }
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showUngroupedGroups,
                        expression: "showUngroupedGroups"
                      }
                    ],
                    staticClass: "line",
                    on: { mousedown: _vm.startDrag }
                  }),
                  _vm.showUngroupedGroups
                    ? _c(
                        "div",
                        { staticClass: "unclassifiedGroups" },
                        [_c("UnclassifiedGroups")],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      !_vm.showUngroupedGroups && !_vm.loading && !_vm.openFolderSearch
        ? _c(
            "div",
            {
              staticClass: "addClassification",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addNewClass.apply(null, arguments)
                }
              }
            },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  draggable: false,
                  src: require("../../../../../assets/images/im/myClassification/addClassification.png"),
                  fit: "fill"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }