<template>
  <div
    class="unclassifiedGroupsBox"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <div class="searchBox">
      <div class="search">
        <span
          class="iconfont_Me icon-search iconStyle"
          @click.stop="handlerSearchClass"
        ></span>
        <el-input
          v-model="searchKey"
          @keydown.enter.native="handlerSearchClass"
        ></el-input>
      </div>
    </div>
    <div
      class="groups"
      v-infinite-scroll="loadData"
      infinite-scroll-disabled="loadDataDisabled"
      infinite-scroll-distance="10"
      infinite-scroll-immediate-check="false"
      v-if="list.length != 0"
    >
      <div
        class="group"
        v-for="(i, index) in list"
        :key="i.id + i.selected"
        @click.stop="check(index)"
        draggable="true"
        @dragstart="handleDragStart($event, index)"
        @dragend="handleDragEnd($event, index)"
      >
        <div class="checkBox" :class="i.selected ? 'selected' : ''">
          <i class="iconfont_Me icon-check iconStyle" v-if="i.selected"></i>
        </div>
        <div class="groupItem" :title="i.name">
          <div class="photo">
            <el-image
              style="width: 100%; height: 100%"
              :src="i.profilePhoto"
              :fit="'fill'"
            ></el-image>
          </div>
          <div class="name">
            {{ i.name }}
          </div>
          <i
            class="iconfont_Me icon-qunzhu ownerIcon"
            v-if="userId == i.groupOwnerId"
          ></i>
        </div>
      </div>
    </div>
    <div class="placeholderMap" v-else>
      <PlaceholderMap :placeholderType="2"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import { listOfUnclassifiedGroups } from "@/api/newVersion/discussionGroupClassification";
import PlaceholderMap from "@/newVersion/components/placeholderMap";
export default {
  name: "UnclassifiedGroups",
  components: {
    PlaceholderMap,
  },
  data() {
    return {
      page: 1,
      pageSize: 30,
      list: [],
      dragContainer: null,
      loadDataDisabled: false,
      loading: false,
      searchKey: "",
    };
  },
  async mounted() {
    await this.getListData();
  },
  computed: {
    userId() {
      return this.$store.getters.userInfo.id;
    },
    selectedUnclassifiedGroups() {
      return this.$store.state.myClassification.selectedUnclassifiedGroups;
    },
    refreshUnclassifiedGroups() {
      return this.$store.state.myClassification.refreshUnclassifiedGroups;
    },
  },
  watch: {
    refreshUnclassifiedGroups: {
      async handler(val, old) {
        if (val && val != old) {
          this.page = 1;
          await this.getListData();
          this.$store.commit("setRefreshUnclassifiedGroups", false);
        }
      },
      deep: true,
    },
  },
  methods: {
    handlerSearchClass() {
      this.page = 1;
      this.getListData();
    },
    check(index) {
      this.list[index].selected = !this.list[index].selected;
      this.$store.commit(
        "setSelectedUnclassifiedGroups",
        this.list.filter((item) => item.selected)
      );
    },
    // 清空除了当前拖动对象外所有数据的选中状态
    clearSelected(id) {
      this.list = this.list.map((item) => {
        if (item.id != id) {
          item.selected = false;
        }
        return item;
      });
    },
    handleDragStart(event, index) {
      event.dataTransfer.setData("nodeData", this.list[index].id);
      event.dataTransfer.setData("nodeType", this.list[index].nodeType);
      this.clearSelected(this.list[index].id);
      this.$store.commit("setGroupIsDragging", true);
    },
    handleDragEnd(event, index) {
      this.$store.commit("setGroupIsDragging", false);
    },
    async loadData() {
      this.loadDataDisabled = true;
      this.page++;
      await this.getListData();
      this.loadDataDisabled = false;
    },
    async getListData() {
      this.loading = true;
      if (this.page == 1) {
        this.$store.commit("setSelectedUnclassifiedGroups", []);
      }
      let params = {
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.searchKey) {
        params.searchWords = this.searchKey;
      }
      const result = await listOfUnclassifiedGroups(params);
      this.loading = false;
      if (result.code == 200) {
        if (result.data.data.length != 0 || this.page == 1) {
          let list = [];
          if (this.page == 1) {
            list = result.data.data ? result.data.data : [];
          } else {
            list = this.list.concat(result.data.data);
          }
          this.list = list.map((item) => {
            item.selected = false;
            return item;
          });
        } else {
          this.page = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus">
.unclassifiedGroupsBox
  .el-input
    height: 100%;
    .el-input__inner
      height: 100% !important;
      border: none;
      background: transparent;
      padding: 0 10px 0 5px !important;
</style>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.unclassifiedGroupsBox
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .searchBox
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 15px;
    margin-top: 11px;
    .search
      width: 200px;
      height: 30px;
      border-radius: 15px;
      box-sizing: border-box;
      padding-left: 5px;
      background: #EDEDED;
      border: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .iconStyle
        font-size: 16px;
        color: #BDBEC0;
        cursor pointer;
  .placeholderMap
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow: hidden;
  .groups
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-light();
    box-sizing: border-box;
    .group
      height: 60px;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding 0 15px;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      background: #ffffff;
      transition: background .5s;
      &:hover
        background: #ebebeb;
      .checkBox
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #D9D9D9;
        transition: all .5s;
        flex-shrink: 0;
      .selected
        border: 1px solid #66C871;
        display: flex;
        align-content: center;
        justify-content: center;
        .iconStyle
          font-size: 12px;
          color: #66C871;
      .groupItem
        flex: 1;
        height: 100%;
        max-width: calc(100% - 14px) ;
        display: flex;
        align-items: center;
        justify-content: center;
        .photo
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow hidden;
          margin-left: 5px;
          flex-shrink: 0;
        .name
          flex: 1;
          font-size: 12px;
          padding-left: 5px;
          box-sizing: border-box;
          max-width: calc(100% - 50px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        .ownerIcon
          width: 20px;
          font-size: 20px;
          color: #65C96E;
          flex-shrink: 0;
</style>
